import React, { useEffect } from "react"; 
import {
  useSearchParams ,
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';

function App() {
  return (

    <Router>
    <div>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/e/:equipment_uuid' element={<Equipment/>} />
      </Routes>
    </div>
  </Router>
  

    
  );
}

export default App;



function Home() {
  return (

    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="https://qlog.co/m"
          rel="noopener noreferrer"
        >
          go to app
        </a>
      </header>
    </div>
  );
}

function Equipment() {
  let { equipment_uuid } = useParams();
  useEffect(() => {
    window.location.href = 'https://qlog.co/m/equipment_by_uuid.asp?equipment_uuid='+equipment_uuid;
  }, []);

}